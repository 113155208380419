<template>
  <div>
    <ValidationObserver v-slot="{ validate }" ref="formValidator">
      <form
          @submit.prevent="validate().then(saveLane(false))"
          class="needs-validation"
          id="add-qo"
      >
        <div>
          <div v-if="isCreateLane" class="create-section-text mb-2">
            Create a new lane for this section
          </div>

          <!-- Create a radio btns for cross border and non-cross border -->
          <div class="d-flex mt-3">
            <div class="text-center border-radios">
              <base-radio name="notCrossBorder" v-model="borderSelected"></base-radio>
              <p> Not Cross border</p>
            </div>
            <div class="text-center border-radios ml-5">
              <base-radio name="crossBorder" v-model="borderSelected"></base-radio>
              <p> Cross border </p>
            </div>
          </div>

          <!-- for not cross border -->
          <div v-if="borderSelected === 'notCrossBorder'">
            <!-- pricing dropdown starts -->
            <div class="create-section-text mb-2 mt-3">
              Select a Pricing Method for this lane base rate
            </div>

            <div class="field-dropdown-main mb-4 price-method-dropdwon">
              <choices-single
                  id="editLanePriceMethod"
                  :options="priceMethodList"
                  v-model="selectedPriceMethod"
                  @input="event => selectPriceMethod(event)"
              >
                <option value="0">Select</option>
              </choices-single>
              <span v-if="priceMethodInvalid" class="validate-error"
              >The Price method is required</span
              >
            </div>

            <div
                class="row"
                v-if="selectedLanePricingMethods && selectedLanePricingMethods.key == 'class'"
            >
              <div class="col-12 col-lg-4">
                <div class="create-section-text mb-2">
                  Choose a Base Rate
                </div>
                <ValidationProvider rules="required" name="Base rate" v-slot="{ errors }">
                  <div class=" mb-4 price-method-dropdwon">
                    <choices-single
                        :options="baseUnitsListing"
                        :value="selectedBaseUnit"
                        v-model="selectedBaseUnit"
                        @input="event => selectBaseUnits(event)"
                    >
                      <option value="0">Select Base rate</option>
                    </choices-single>

                    <span class="validate-error">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>

              <div class="col-12 col-lg-4 mt-lg-4">
                <button type="button" class="btn btn-default mb-4 mb-lg-0 mt-lg-2">
                  Authenticate
                </button>
              </div>
            </div>
            <!-- pricing dropdown ends -->

            <div class="row">
              <div class="col-md-12 col-lg-5">
                <base-input
                    type="text"
                    label="Name"
                    name="Name"
                    rules="required"
                    v-model="name"
                >
                </base-input>
              </div>
              <div class="col-lg-6"></div>
            </div>

            <!-- Render this section if lane pricing methods is class -->
            <div v-if="selectedLanePricingMethods && selectedLanePricingMethods.key == 'class'">
              <div class="row mb-3">
                <div class="col-md-12 col-lg-5">
                  <choices-single
                      label="Origin"
                      v-model="origin"
                      :options="originDestinationList"
                      :value="origin"
                  >
                    <option value="0">Select origin</option>
                  </choices-single>
                </div>
              </div>
              <div class="row  mb-3">
                <div class="col-md-12 col-lg-5">
                  <choices-single
                      label="Destination"
                      v-model="destination"
                      :options="originDestinationList"
                      :value="destination"
                  >
                    <option value="0">Select destination</option>
                  </choices-single>
                </div>
              </div>
            </div>

            <!-- Render this section if lane pricing methods is not 'class' -->
            <div v-else>
              <div class="row">
                <div class="col-md-12 col-lg-5">
                  <div class="form-group has-label">
                    <label> Origin </label>
                    <vue-google-autocomplete
                        classname="form-control mb-1"
                        placeholder="City - Postal code"
                        id="origin-map"
                        v-model="origin"
                        types="(regions)"
                        :country="country"
                        v-on:change="getOriginData"
                    >
                    </vue-google-autocomplete>
                    <span v-if="originInvalid" class="validate-error"
                    >The Origin field is required</span
                    >
                  </div>
                  <div class="mb-3" v-if="!isAddSubCity">
                    <a class="btn btn-link a-btn" @click="addSubCity">
                      Add Sub-city +
                    </a>
                  </div>
                </div>
                <div class="col-lg-7"></div>
              </div>
              <div v-if="isAddSubCity" class="row">
                <div class="col-md-2"></div>
                <div class="col-md-12 col-lg-6">
                  <div
                      v-for="(item, index) in additionalSubCityList"
                      :key="index"
                      class="row mb-2"
                  >
                    <div class="col-10 col-md-10 autocomplete-pr has-label">
                      <vue-google-autocomplete
                          :ref="item.city"
                          classname="form-control mb-1"
                          placeholder="City - Postal code"
                          :id="'sub_city_' + index"
                          v-model="item.city"
                          types="(regions)"
                          :country="country"
                          @inputChange="setSubCityIndex(index)"
                          v-on:change="getSubCityData"
                      >
                      </vue-google-autocomplete>
                    </div>
                    <div class="col-2 col-md-2">
                      <i
                          class="fa fa-trash delete-icon"
                          @click="removeSubCity(index)"
                      >
                      </i>
                    </div>
                  </div>
                  <div class="mb-3 mt-2">
                    <a class="btn btn-link a-btn" @click="addAdditionalSubCity">
                      Add additional sub-city +
                    </a>
                  </div>
                </div>
                <div class="col-lg-5"></div>

              </div>
              <div class="row">
                <div class="col-md-12 col-lg-5">
                  <div class="form-group has-label">
                    <label> Destination </label>
                    <vue-google-autocomplete
                        classname="form-control mb-1"
                        placeholder="City - Postal code"
                        id="destination-map"
                        v-model="destination"
                        types="(regions)"
                        :country="country"
                        v-on:change="getDestinationData"
                    >
                    </vue-google-autocomplete>
                    <span v-if="destinationInvalid" class="validate-error"
                    >The Destination field is required</span
                    >
                  </div>
                  <div class="mb-3 mt-2" v-if="!isAddCityToGroup">
                    <a class="btn btn-link a-btn" @click="addGroupCity">
                      Add city to group +
                    </a>
                  </div>
                </div>
                <div class="col-lg-7"></div>
              </div>
              <div v-if="isAddCityToGroup" class="row">
                <div class="col-lg-2"></div>
                <div class="col-md-12 col-lg-6">
                  <div
                      v-for="(item, index) in additionalCityToGroupList"
                      :key="index"
                      class="row mb-2"
                  >
                    <div class="col-10 col-md-10 autocomplete-pr has-label">
                      <vue-google-autocomplete
                          :ref="item.city"
                          classname="form-control mb-1"
                          placeholder="City - Postal code"
                          :id="'group_city_' + index"
                          v-model="item.city"
                          types="(regions)"
                          :country="country"
                          @inputChange="setGroupCityIndex(index)"
                          v-on:change="getGroupCityData"
                      >
                      </vue-google-autocomplete>
                    </div>
                    <div class="col-2 col-md-2">
                      <i
                          class="fa fa-trash delete-icon"
                          @click="removeGroupCity(index)"
                      >
                      </i>
                    </div>
                  </div>
                  <div class="mb-3 mt-2">
                    <a class="btn btn-link a-btn" @click="addAdditionalGroupCity">
                      Add additional city to group +
                    </a>
                  </div>
                </div>
                <div class="col-md-5"></div>
              </div>
            </div>

            <label>
              Is this a between lane?
            </label>

            <base-radio name="between" class="mb-3" v-model="between">
              Between
            </base-radio>
            <base-radio name="notBetween" class="mb-3" v-model="between">
              Not Between
            </base-radio>

            <div class="row">
              <div class="col-md-12 col-lg-5">
                <base-input
                    type="number"
                    label="Transit Time in Days"
                    step="any"
                    class="mb-3"
                    placeholder=""
                    name="Transit Time in Days"
                    v-model="transitDays"
                    :max="100"
                    min="1"
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-lg-5">
                <div class="form-group has-label">
                  <label> Description for this lane (optional) </label>
                  <textarea
                      label="Name of Section"
                      size="sm"
                      class="form-control mb-4"
                      rows="6"
                      max-rows="6"
                      v-model="description"
                  >
                  </textarea>
                </div>
              </div>
              <div class="col-lg-7"></div>
            </div>

            <!-- Currency type dropdown starts-->
            <currency-selection
              @currency-default-changed="handleCurrencyDefaultChange"
              :currency.sync="currency"
            />
            <!-- Currency type dropdown ends -->

            <!-- Selected value appears on pricing method -->
            <div class="create-section-text mb-2">
              Selected Pricing Method for this lane
            </div>

            <div class="field-dropdown-main mb-4 price-method-dropdwon">
              <choices-single
                  :key="selectedPriceMethod"
                  :isDisabled="true"
              >
                <option>{{ selectedPriceMethod ? selectedPriceMethod : 'Select' }}</option>
              </choices-single>
            </div>

            <div
                class="fixed-input"
                v-if="selectedLanePricingMethods && selectedLanePricingMethods.key == 'fixed'"
            >
              <span class="data-type-input left">
                <input
                    type="number"
                    step="any"
                    class="form-control"
                    placeholder="00.00"
                    v-model="dollarValue"
                    @input="event => changeValue(event)"
                    @blur="addDollarDecimal"
                />
              </span>
              <span v-if="dollarValueInvalid" class="validate-error"
              >The Price field is required</span
              >
            </div>

            <div
                v-if="selectedLanePricingMethods && selectedLanePricingMethods.key != 'fixed'"
            >

              <lane-pricing-not-fixed
                  :unitPriceList="unitPriceList"
                  :selectedLanePricingMethods="selectedLanePricingMethods"
                  :currencySymbol="currencySymbol"
              />

              <div
                  class="mt-3"
                  v-if="selectedLanePricingMethods.allowMultipleValues"
              >
                <base-button @click="addUnitPrice">
                  Add +
                </base-button>
              </div>
            </div>


            <!-- for base rate start-->
            <div v-if="selectedLanePricingMethods && selectedLanePricingMethods.key == 'class'">
              <!-- here Class dropdown code -->
              <lane-pricing-class
                  :selectedBaseUnit="selectedBaseUnit"
                  :selectedClassUnits="selectedClassUnits"
                  :isViewClassBreaks="isViewClassBreaks"
                  @update:isViewClassBreaks="isViewClassBreaks = $event"
                  @select-class-units="selectClassUnits"
                  :classRateInvalid="classRateInvalid"
                  :currencySymbol="currencySymbol"
              />
            </div>
            <!-- for base rate end-->
          </div>

          <!-- for cross border -->
          <div v-else>
            <div class="row mt-3">
              <div class="col-md-12 col-lg-5 cross-border-inputs">
                <base-input
                    type="text"
                    label="Name"
                    name="Name"
                    rules="required"
                    v-model="name"
                    placeholder="Internal Lane Name"
                >
                </base-input>
              </div>
              <div class="col-lg-6"></div>
            </div>

            <!-- for origin -->
            <cross-border-origin-destination
                label="Origin"
                :originDestination="origin"
                :priceMethodList="priceMethodList"
                :selectedPriceMethod="selectedPriceMethod"
                :selectedLanePricingMethods="selectedLanePricingMethods"
                :unitPriceList="unitPriceList"
                :selectedBaseUnit="selectedBaseUnit"
                :selectedClassUnits="selectedClassUnits"
                :isViewClassBreaks="isViewClassBreaks"
                :classRateInvalid=classRateInvalid
                :baseUnitsListing="baseUnitsListing"
                :isAddCityToGroup="isAddSubCity"
                :additionalCityToGroupList="additionalSubCityList"
                :dollarValue="dollarValue"
                :dollarValueInvalid.sync="dollarValueInvalid"
                :priceMethodInvalid.sync="priceMethodInvalid"
                :originInvalid="originInvalid"
                :currency.sync="originCurrency"

                @get-origin-data="getOriginData"
                @add-dollar-decimal="addDollarDecimal"
                @set-group-city-index="setSubCityIndex"
                @select-price-method="selectPriceMethod"
                @add-unit-price="addUnitPrice"
                @add-group-city="addSubCity"
                @add-additional-group-city="addAdditionalSubCity"
                @get-group-city-data="getSubCityData"
                @remove-group-city="removeSubCity"
                @change-value="changeValue"
                @select-base-units="selectBaseUnits"
                @update:isViewClassBreaks="isViewClassBreaks = $event"
                @select-class-units="selectClassUnits"
            />

            <div class="row mb-3 mt-3">
              <div class="col-md-12 col-lg-5 has-label">
                <div class="create-section-text mb-2">
                  Border Crossing Point
                </div>
                <vue-google-autocomplete
                    classname="form-control mb-1"
                    placeholder="City - Postal code"
                    id="border-crossing-point"
                    v-model="borderCrossingPoint"
                    types="(regions)"
                    :country="country"
                    v-on:change="getBorderCrossingPoint"
                >
                </vue-google-autocomplete>
                <span v-if="borderCrossingPointInvalid" class="validate-error">
                  The Border Crossing Point field is required
                </span>
              </div>
            </div>

            <!-- for destination -->
            <cross-border-origin-destination
                label="Destination"
                :originDestination="destination"
                :priceMethodList="priceMethodList"
                :selectedPriceMethod="selectedDestinationPriceMethod"
                :selectedLanePricingMethods="selectedDestinationLanePricingMethods"
                :unitPriceList="unitPriceDestinationList"
                :selectedBaseUnit="selectedDestinationBaseUnit"
                :selectedClassUnits="selectedDestinationClassUnits"
                :isViewClassBreaks="isDestinationViewClassBreaks"
                :classRateInvalid="classRateDestinationInvalid"
                :baseUnitsListing="baseUnitsListing"
                :isAddCityToGroup="isAddCityToGroup"
                :additionalCityToGroupList="additionalCityToGroupList"
                :dollarValue="dollarDestinationValue"
                :dollarValueInvalid.sync="dollarDestinationValueInvalid"
                :priceMethodInvalid.sync="priceMethodDestinationInvalid"
                :destinationInvalid="destinationInvalid"
                :currency.sync="destinationCurrency"

                @get-destination-data="getDestinationData"
                @select-destination-price-method="selectDestinationPriceMethod"
                @add-unit-price="addDestinationUnitPrice"
                @select-class-units="selectDestinationClassUnits"
                @update:isViewClassBreaks="isViewClassBreaks = $event"
                @select-base-units="selectBaseDestinationUnits"
                @add-group-city="addGroupCity"
                @add-additional-group-city="addAdditionalGroupCity"
                @get-group-city-data="getGroupCityData"
                @remove-group-city="removeGroupCity"
                @change-value="changeDestinationValue"
                @add-dollar-decimal="addDollarDecimalDestination"
                @set-group-city-index="setGroupCityIndex"
            />
            <label class="mt-3">
              Is this a between lane?
            </label>

            <base-radio name="between" class="mb-3" v-model="between">
              Between
            </base-radio>
            <base-radio name="notBetween" class="mb-3" v-model="between">
              Not Between
            </base-radio>

            <div class="row">
              <div class="col-md-12 col-lg-5">
                <base-input
                    type="number"
                    label="Transit Time in Days"
                    step="any"
                    class="mb-3"
                    placeholder=""
                    name="Transit Time in Days"
                    v-model="transitDays"
                    :max="100"
                    min="1"
                    rules="required"
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-lg-5">
                <div class="form-group has-label">
                  <label> Description for this lane (optional) </label>
                  <textarea
                      label="Name of Section"
                      size="sm"
                      class="form-control mb-4"
                      rows="6"
                      max-rows="6"
                      v-model="description"
                  >
                  </textarea>
                </div>
              </div>
              <div class="col-lg-7"></div>
            </div>
          </div>

          <min-max-charges-input
              v-if="selectedPriceMethod !== 'Fixed'"
              :max-input-available="false"
              ref="minMaxChargeInput"
          />
          <additional-fixed-charges-input
              :additional-input-available="true"
              ref="additionalChargeInput"
          />
          <pallet-maximum-weight
              v-if="selectedPriceMethod === 'Per Pallet'"
              :pallet-maximum-available="true"
              ref="palletMaximumWeight"
          />
          <button type="submit" class="btn mt-3 btn-default">
            Save
          </button>

          <div>
            <button
                type="button"
                class="btn mt-3 btn-default"
                @click="saveLane(true)"
            >
              Save and Exit
            </button>
          </div>

        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import ChoicesSingle from '@/components/SingleSelect';
import {API, masterAPI} from '@/api/API';
import {
  accountModuleApi,
  getCurrencyType,
  getMethodKeyByName,
  getOptionDetailByName,
  pricingMethodType,
  sectionList,
} from '@/helpers/utility';
import MinMaxChargesInput from '@/views/common/MinMaxChargesInput.vue';
import AdditionalFixedChargesInput from '@/views/common/AdditionalFixedChargesInput.vue';
import PalletMaximumWeight from '@/views/common/PalletMaximumWeight.vue';
import CurrencySelection from '@/views/common/CurrencySelection.vue';
import LanePricingNotFixed from '../common/LanePricingNotFixed.vue';
import LanePricingClass from '../common/LanePricingClass.vue';
import CrossBorderOriginDestination from '../common/CrossBorderOriginDestination.vue';

export default {
  bodyClass: 'landing',
  components: {
    MinMaxChargesInput,
    AdditionalFixedChargesInput,
    PalletMaximumWeight,
    VueGoogleAutocomplete,
    ChoicesSingle,
    CurrencySelection,
    LanePricingNotFixed,
    LanePricingClass,
    CrossBorderOriginDestination,
  },
  data: function() {
    return {
      // Data properties
      country: ['us', 'ca'],
      origin: '',
      destination: '',
      additionalSubCityList: [],
      additionalCityToGroupList: [],
      between: 'notBetween',
      name: '',
      transitDays: null,
      description: '',
      selectedPriceMethod: null,
      unitPriceList: [],
      dollarValue: '',
      isAddSubCity: false,
      isAddCityToGroup: false,
      subCityIndex: 0,
      groupCityIndex: 0,
      originInvalid: false,
      destinationInvalid: false,
      priceMethodInvalid: false,
      dollarValueInvalid: false,
      priceMethodList: [],
      dbLane: [],
      isDuplicate: false,
      lanesList: [],
      selectedLanePricingMethods: null,
      alreadySavedLane: false,
      id: '',
      selectedBaseUnit: null,
      baseUnitsListing: [],
      selectedClassUnits: '',
      isViewClassBreaks: false,
      classRateInvalid: false,
      price: '',
      discount: '',
      currency: null,
      originDestinationList: [],
      borderSelected: 'notCrossBorder',
      borderCrossingPoint: '',

      // states for cross-border destination
      dollarDestinationValue: '',
      selectedDestinationPriceMethod: null,
      selectedDestinationLanePricingMethods: null,
      unitPriceDestinationList: [],
      selectedDestinationBaseUnit: null,
      selectedDestinationClassUnits: '',
      isDestinationViewClassBreaks: false,
      originCurrency: null,
      destinationCurrency: null,
      priceMethodDestinationInvalid: false,
      borderCrossingPointInvalid: false,
      dollarDestinationValueInvalid: false,
      classRateDestinationInvalid: false,
    };
  },
  props: {
    // Component props
    isCreateLane: {
      type: Boolean,
    },
  },
  watch: {
    // Watchers for data properties
    origin: {
      handler(value) {
        this.changeFormHandler('origin', value);
      },
      immediate: true,
    },
    destination: {
      handler(value) {
        this.changeFormHandler('destination', value);
      },
      immediate: true,
    },
    between: {
      handler(value) {
        this.changeFormHandler('isBetween', this.getBetween(value));
      },
      immediate: true,
    },
    name: {
      handler(value) {
        this.changeFormHandler('name', value);
      },
      immediate: true,
    },
    description: {
      handler(value) {
        this.changeFormHandler('description', value);
      },
      immediate: true,
    },
    dollarValue: {
      handler(value) {
        this.changeFormHandler('dollarValue', value);
      },
      immediate: true,
    },
    // Watch for changes in `borderSelected` radio buttons
    borderSelected(newVal) {
      this.origin = '';
      this.destination = '';
      this.name = '';
      this.isAddSubCity = false;
      this.isAddCityToGroup = false;
      this.additionalSubCityList = [];
      this.additionalCityToGroupList = [];
      this.selectedLanePricingMethods = null;
      this.selectedDestinationLanePricingMethods = null;
      this.borderCrossingPoint = '';
      this.dollarValue = '';
      this.dollarDestinationValue = '';
      if (newVal) {
        document.querySelector(
            '#editLanePriceMethod .choices__item',
        ).innerText = 'Select';
      }
    },
  },
  computed: {
    // Computed property for laneId
    laneId() {
      return +this.$route.params.id;
    },
    currentViewName() {
      return this.$route.params.view;
    },
    currencySymbol() {
      const currencyType = getCurrencyType(this.currency);
      return currencyType;
    },
  },
  methods: {
    /**
     * Event handler for changing all fields
     * @param {string} field - The field being changed
     * @param {string} value - The new value of the field
     */
    changeFormHandler(field, value) {
      this.isDuplicate = value != this.dbLane[field];
    },
    /**
     * Event handler for changing currency type.
     */
    handleCurrencyDefaultChange(newValue) {
      this.currency = newValue;
    },
    /**
     * Add a sub city to the additional sub city list
     */
    addSubCity() {
      if (this.origin == '') {
        this.originInvalid = true;
      } else {
        this.isAddSubCity = true;
        this.addAdditionalSubCity();
      }
    },
    /**
     * Add an additional city to the sub city list
     */
    addAdditionalSubCity() {
      if (this.origin == '') {
        this.originInvalid = true;
      } else {
        this.additionalSubCityList.push({
          city: '',
        });

        setTimeout(() => {
          const lastIndex = this.additionalSubCityList.length - 1;
          document.getElementById('sub_city_' + lastIndex).focus();
        }, 100);
      }
      this.isDuplicate = true;
    },
    /**
     * Remove a sub city from the additional sub city list
     * @param {number} index - The index of the sub city to remove
     */
    removeSubCity(index) {
      this.additionalSubCityList.splice(index, 1);
      if (this.additionalSubCityList.length == 0) {
        this.isAddSubCity = false;
      }
      let vm = this;
      setTimeout(function() {
        vm.additionalSubCityList.map(item => {
          vm.$refs[item.city][0].update(item.city);
        });
      }, 50);
      this.isDuplicate = true;
    },
    /**
     * Add a group city to the additional city list
     */
    addGroupCity() {
      if (this.destination == '') {
        this.destinationInvalid = true;
      } else {
        this.isAddCityToGroup = true;
        this.addAdditionalGroupCity();
      }
    },
    /**
     * Add an additional city to the group city list
     */
    addAdditionalGroupCity() {
      if (this.destination == '') {
        this.destinationInvalid = true;
      } else {
        this.additionalCityToGroupList.push({
          city: '',
        });

        setTimeout(() => {
          const lastIndex = this.additionalCityToGroupList.length - 1;
          document.getElementById('group_city_' + lastIndex).focus();
        }, 100);
      }
      this.isDuplicate = true;
    },
    /**
     * Remove a group city from the additional city list
     * @param {number} index - The index of the group city to remove
     */
    removeGroupCity(index) {
      this.additionalCityToGroupList.splice(index, 1);
      if (this.additionalCityToGroupList.length == 0) {
        this.isAddCityToGroup = false;
      }

      let vm = this;
      setTimeout(function() {
        vm.additionalCityToGroupList.map(item => {
          vm.$refs[item.city][0].update(item.city);
        });
      }, 50);
      this.isDuplicate = true;
    },
    /**
     * Add a unit price for the selected lane pricing methods
     */
    async addUnitPrice() {
      this.addUnitPriceCommon(this.selectedLanePricingMethods, this.unitPriceList);
    },

    /**
     * Add a unit price for the selected destination lane pricing methods
     */
    async addDestinationUnitPrice() {
      this.addUnitPriceCommon(this.selectedDestinationLanePricingMethods, this.unitPriceDestinationList);
    },

    /**
     * Common function to handle adding unit prices
     * @param {Object} selectedPricingMethods - The selected pricing methods containing allowed values
     * @param {Array} unitPriceList - The list where the new unit price object will be added
     */
    addUnitPriceCommon(selectedPricingMethods, unitPriceList) {
      let unitPriceObj = {};
      if (selectedPricingMethods) {
        const priceKey = selectedPricingMethods.allowedValues.find(
            value => value.key === 'price',
        );

        if (priceKey) {
          unitPriceObj.price = '';
          selectedPricingMethods.allowedValues.forEach(allowedValue => {
            if (allowedValue.key !== 'price') {
              unitPriceObj[allowedValue.key] = '';
            }
          });

          unitPriceList.push(unitPriceObj);
        }
      }
    },
    /**
     * Get the BaseRate listing data
     */
    async getBaseRates() {
      // Make API call to fetch baserate data
      let response = await masterAPI(
          API.API_ENDPOINT.baseRate,
          API.API_METHOD.get,
          undefined,
          undefined,
      );

      if (response.status == 200) {
        let baseRateData = response.data;
        this.baseUnitsListing = baseRateData.map((baseRate, index) => ({
          id: index,
          text: baseRate.name,
        }));
      }
    },
    /**
     * Save the lane by checking if it is a new lane or an edit/duplicate operation
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save or update.
     */
    async saveLane(isSaveExit) {
      if(this.isSelectedCrossBorder()) {
        this.crossBorderValidation(isSaveExit)
      } else {
        this.notCrossBorderValidation(isSaveExit)
      }
    },
    /**
     * Cross border validation 
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save or update.
     */
    async crossBorderValidation(isSaveExit) {
      // 1. Validate origin
      if (this.origin === '') {
        this.originInvalid = true;
        return;
      }

      // 2. Validate pricing methods and conditions
      if (!this.selectedPriceMethod) {
        this.priceMethodInvalid = true;
        return;
      } else if (this.selectedPriceMethod === 'Fixed' && this.selectedDestinationPriceMethod === 'Fixed') {
        // Both Fixed
        let isValid = true;
        if (this.dollarValue === '') {
          this.dollarValueInvalid = true;
          isValid = false;
        } else {
          this.dollarValueInvalid = false;
        }

        if (this.dollarDestinationValue === '') {
          this.dollarDestinationValueInvalid = true;
          isValid = false;
        } else {
          this.dollarDestinationValueInvalid = false;
        }

        if (isValid) {
          await this.$refs.formValidator.validate().then(async (isSuccess) => {
            if (isSuccess) {
              this.checkAddEditDuplicateApi(isSaveExit);
            }
          });
        }
        return;
      } else if (this.selectedPriceMethod === 'Fixed') {
        // Fixed and different destination method
        let isValid = true;
        if (this.dollarValue === '') {
          this.dollarValueInvalid = true;
          isValid = false;
        } else {
          isValid = true;
          this.dollarValueInvalid = false;
        }

        if (this.unitPriceDestinationList.length === 0) {
          this.$toast.error('Please add a pricing value.');
          isValid = false;
        }

        if (isValid) {
          await this.$refs.formValidator.validate().then(async (isSuccess) => {
            if (isSuccess) {
              this.checkAddEditDuplicateApi(isSaveExit);
            }
          });
        }
        return;
      } else if (this.selectedDestinationPriceMethod === 'Fixed') {
        let isValid = true;
        if (this.dollarDestinationValue === '') {
          this.dollarDestinationValueInvalid = true;
          isValid = false;
        } else {
          isValid = true;
          this.dollarDestinationValueInvalid = false;
        }

        if (this.unitPriceList.length === 0) {
          this.$toast.error('Please add a pricing value.');
          isValid = false;
        }

        if (isValid) {
          await this.$refs.formValidator.validate().then(async (isSuccess) => {
            if (isSuccess) {
              this.checkAddEditDuplicateApi(isSaveExit);
            }
          });
        }
        return;
      }

      // 3. Validate Border Crossing Point
      if (!this.borderCrossingPoint) {
        this.borderCrossingPointInvalid = true;
        return;
      }

      // 4. Validate destination
      if (this.destination === '') {
        this.destinationInvalid = true;
        return;
      }

      // 5. Validate Destination Pricing Methods and conditions
      if (!this.selectedDestinationPriceMethod) {
        this.priceMethodDestinationInvalid = true;
        return;
      }

      // Handle fallback cases when neither 'Class' nor 'Fixed' is selected
      if (this.selectedPriceMethod !== 'Class' && this.selectedPriceMethod !== 'Fixed') {
        if (this.unitPriceList.length === 0) {
          this.$toast.error('Please add a pricing value.');
          return;
        }
      }

      if (this.selectedDestinationPriceMethod !== 'Class' && this.selectedDestinationPriceMethod !== 'Fixed') {
        if (this.unitPriceDestinationList.length === 0) {
          this.$toast.error('Please add a pricing value.');
          return;
        }
      }

      // Final validation
      try {
        const isSuccess = await this.$refs.formValidator.validate();
        if (isSuccess) {
          this.checkAddEditDuplicateApi(isSaveExit);
        }
      } catch (error) {
        this.$toast.error('An error occurred during validation.');
      }
    },
    /**
     * Not-Cross border validation 
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save or update.
     */
     notCrossBorderValidation(isSaveExit) {
        if (this.origin == '') {
          this.originInvalid = true;
        } else if (this.destination == '') {
          this.destinationInvalid = true;
        } else if (!this.selectedPriceMethod) {
          this.priceMethodInvalid = true;
        } else {
          if (this.selectedPriceMethod == 'Fixed') {
            console.log('HERE');
            if (this.dollarValue === '') {
              console.log(this.dollarValue)
              this.dollarValueInvalid = true;
            } else {
              this.$refs.formValidator.validate().then(async isSuccess => {
                if (isSuccess) {
                  this.checkAddEditDuplicateApi(isSaveExit);
                }
              });
            }
          } else if (this.selectedLanePricingMethods.key == 'class') {
            if (!this.selectedClassUnits) {
              this.classRateInvalid = true;
            } else {
              this.$refs.formValidator.validate().then(async isSuccess => {
                if (isSuccess) {
                  this.checkAddEditDuplicateApi(isSaveExit);
                }
              });
            }
          } else {
            if (this.unitPriceList.length == 0) {
              this.$toast.error('Please add a pricing value.');
            } else {
              this.$refs.formValidator.validate().then(async isSuccess => {
                if (isSuccess) {
                  this.checkAddEditDuplicateApi(isSaveExit);
                }
              });
            }
          }
        }
      
    },
    /**
     * Check whether to add a new lane or duplicate an existing one based on the context
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save or update.
     */
    checkAddEditDuplicateApi(isSaveExit) {
      if (this.isCreateLane) {
        this.isDuplicate = true;
        if (this.alreadySavedLane) {
          this.updateLane(isSaveExit);
        } else {
          this.createLane(isSaveExit);
        }
      } else {
        if (this.currentViewName === 'duplicate') {
          this.createLane(isSaveExit);
        } else {
          this.updateLane(isSaveExit);
        }
      }
    },
    /**
     * Checks whether the currently selected lane is a cross-border lane.
     *
     * @returns {boolean} True if the selected lane is a cross-border lane, otherwise false.
    */
    isSelectedCrossBorder() {
      return this.borderSelected === "crossBorder";
    },
    /**
     * Update the lane
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the update.
     */
    async updateLane(isSaveExit) {
      let pricingMethodValue;

      if (this.selectedPriceMethod === 'Fixed') {
        pricingMethodValue = this.dollarValue;
      } else {
        pricingMethodValue = this.unitPriceList;
      }

      let subCity = {
        origin: this.additionalSubCityList,
        destination: this.additionalCityToGroupList,
      };
      let minChargeValue;
      if (this.$refs.minMaxChargeInput) {
        let minMaxValid = this.$refs.minMaxChargeInput.checkMinMaxValueValidation();
        if (!minMaxValid) {
          this.$toast.error('Check your min value');
        }
        minChargeValue = this.$refs.minMaxChargeInput.minValue;
      }

      let additionalValid = this.$refs.additionalChargeInput.checkAdditionalValueValidation();
      if (!additionalValid) {
        this.$toast.error('Check your additional charge value');
      }

      let palletMaximumWeight;
      if (this.$refs.palletMaximumWeight) {
        let palletMaximumValid = this.$refs.palletMaximumWeight.checkPalletMaximumValidation();
        if (!palletMaximumValid) {
          this.$toast.error('Check your pallet maximum weight value');
        }
        palletMaximumWeight = this.$refs.palletMaximumWeight.palletMaximumValue;
      }

      let pricingMethod = {
        type: this.selectedLanePricingMethods.key,
        value: pricingMethodValue,
        min: minChargeValue,
        additionalCharge: {
          label: this.$refs.additionalChargeInput.additionalValueLabel,
          value: this.$refs.additionalChargeInput.additionalValue,
        },
        palletMaximumWeight,
      };

      let crossBorder = this.isSelectedCrossBorder();

      let bodyPayload = {
        origin: this.origin,
        destination: this.destination,
        subCity: subCity,
        isBetween: this.between === "between",
        name: this.name,
        description: this.description,
        pricingMethod: pricingMethod,
        metadata: {
          transitDays: this.transitDays > 0 ? this.transitDays : undefined,
          currency: this.currency,
          crossBorder: crossBorder,
        },
      };

      // If the lane involves a cross-border shipment, update the payload
      if (crossBorder) {
        bodyPayload = this.crossBorderPayload(bodyPayload);
      }
      
      let currentLaneId = this.laneId ? this.laneId : this.id;

      let response = await masterAPI(
          API.API_ENDPOINT.lane,
          API.API_METHOD.put,
          currentLaneId,
          JSON.stringify(bodyPayload),
      );

      if (response.status == 200) {
        this.$toast.success('Lane update successfully');
        if (isSaveExit) {
          this.$router.push({
            name: 'viewallsection',
            params: {preselected: sectionList.lane},
          });
        } else {
          this.getAllLanes();
        }
      } else {
        let error = response.data.message;
        this.$toast.error(error.replaceAll('"', ''));
      }
    },
    /**
     * Get the "between" value based on the selected option
     * @param {string} value - The selected value
     * @returns {boolean} - True if the value is "between", false otherwise
     */
    getBetween(value) {
      return value == 'between' ? true : false;
    },
    /**
     * Display an error toast message
     */
    displayError() {
      this.$toast.error(
          'Lane cannot be duplicated until a change as been made',
      );
    },
    /**
     * Handle the selection of a price method for the origin pricing
     * @param {Event} e - The event object
     */
    selectPriceMethod(e) {
      this.selectPriceMethodCommon(e, 'selectedLanePricingMethods', 'unitPriceList');
      this.selectedPriceMethod = e;
    },
    /**
     * Handle the selection of a price method for the destination pricing
     * @param {Event} e - The event object containing the selected price method
     */
    selectDestinationPriceMethod(e) {
      this.selectPriceMethodCommon(e, 'selectedDestinationLanePricingMethods', 'unitPriceDestinationList');
      this.selectedDestinationPriceMethod = e;
    },
    /**
     * Common function to handle the selection of a price method
     * @param {Event} e - The event object containing the selected price method
     * @param {String} selectedPriceMethodKey - The key of the property to update with the selected price method
     * @param {String} unitPriceListKey - The key of the property to clear if the selected price method changes
     */
    selectPriceMethodCommon(e, selectedPriceMethodKey, unitPriceListKey) {
      let priceMethodDetail = getOptionDetailByName(this.priceMethodList, e);
      this[selectedPriceMethodKey] = priceMethodDetail;
      this.priceMethodInvalid = false;
      this.priceMethodDestinationInvalid = false;
      let oldPriceMethod = localStorage.selectedPriceMethod;
      if (oldPriceMethod && oldPriceMethod !== e) {
        this[unitPriceListKey] = [];
      }

      if (this[selectedPriceMethodKey].key === 'class') {
        this.getBaseRates();
        this.getOriginDestinationViaApi();
      }
    },
    /**
     * Handle the change of a value for the origin pricing
     * @param {Event} e - The event object
     */
    changeValue(newValue) {
      this.dollarValueInvalid = false;
      this.changeValueCommon(newValue, this.borderSelected !== 'notCrossBorder', 'dollarValue');
    },
    /**
     * Handle the change of a value for the destination pricing
     * @param {String} newValue
     */
    changeDestinationValue(newValue) {
      this.dollarDestinationValue = false;
      this.changeValueCommon(newValue, true, 'dollarDestinationValue');
    },
    /**
     * Common function to process and format a value
     */
    changeValueCommon(newValue, isCrossBorder, valueKey) {
      

      let fixedValue = isCrossBorder ? newValue : this[valueKey];
      if (fixedValue.includes('.')) {
        const parts = fixedValue.split('.');
        if (parts[1] && parts[1].length > 2) {
          this[valueKey] = `${parts[0]}.${parts[1].substr(0, 2)}`;
        } else {
          this[valueKey] = fixedValue;
        }
      } else {
        this[valueKey] = fixedValue;
      }

      this.isDuplicate = true;
    },
    /**
     * Add decimal places to the dollar value for the origin pricing if not present
     */
    addDollarDecimal() {
      this.addDollarDecimalCommon('dollarValue');
    },
    /**
     * Add decimal places to the dollar value for the destination pricing if not present
     */
    addDollarDecimalDestination() {
      this.addDollarDecimalCommon('dollarDestinationValue');
    },
    /**
     * Common function to add decimal places to a dollar value if not already present
     * @param {String} valueKey - The key of the state property to update with the formatted value
     */
    addDollarDecimalCommon(valueKey) {
      let fixedValue = this[valueKey];
      if (!fixedValue.includes('.')) {
        this[valueKey] = fixedValue + '.00';
      }
    },
    /**
     * Get the origin data from an address autocomplete component
     * @param {string} userInput - The selected address
     */
    getOriginData(userInput) {
      this.origin = userInput;
      this.originInvalid = false;
    },
    /**
     * Get the destination data from an address autocomplete component
     * @param {string} userInput - The selected address
     */
    getDestinationData(userInput) {
      this.destination = userInput;
      this.destinationInvalid = false;
    },
    /**
     * Set the index of the sub city being modified
     * @param {number} i - The index of the sub city
     */
    setSubCityIndex(i) {
      this.subCityIndex = i;
    },
    /**
     * Get the sub city data from an address autocomplete component
     * @param {string} userInput - The selected address
     */
    getSubCityData(userInput) {
      this.additionalSubCityList[this.subCityIndex].city = userInput;
      this.isDuplicate = true;
    },
    /**
     * Set the index of the group city being modified
     * @param {number} i - The index of the group city
     */
    setGroupCityIndex(i) {
      this.groupCityIndex = i;
    },
    /**
     * Get the group city data from an address autocomplete component
     * @param {string} userInput - The selected address
     */
    getGroupCityData(userInput) {
      this.additionalCityToGroupList[this.groupCityIndex].city = userInput;
      this.isDuplicate = true;
    },
    /**
     * Set the placeholders for unit input fields based on the selected price method
     * @param {string} priceMethod - The selected price method
     * @returns {string} - The placeholder value
     */
    setUnitPlacehoders(priceMethod) {
      let placeholder = '';
      if (priceMethod == pricingMethodType.cwt) {
        placeholder = 'CWT';
      } else if (priceMethod == pricingMethodType.volume) {
        placeholder = 'ft³';
      } else if (priceMethod == pricingMethodType.linearFoot) {
        placeholder = 'LFT';
      } else if (priceMethod == pricingMethodType.weightRange) {
        placeholder = 'lbs';
      } else if (priceMethod == pricingMethodType.perPallet) {
        let unitLength = this.unitPriceList.length;
        placeholder = unitLength <= 1 ? 'pallet' : 'pallets';
      }
      return placeholder;
    },
    /**
     * Create a new lane via an API call
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save.
     */
    async createLane(isSaveExit) {
      if (this.isDuplicate) {
        let pricingMethodValue;

        if (this.selectedPriceMethod == 'Fixed') {
          pricingMethodValue = this.dollarValue;
        } else {
          pricingMethodValue = this.unitPriceList;
        }

        let subCity = {
          origin: this.additionalSubCityList,
          destination: this.additionalCityToGroupList,
        };
        let minChargeValue;
        if (this.$refs.minMaxChargeInput) {
          let minMaxValid = this.$refs.minMaxChargeInput.checkMinMaxValueValidation();
          if (!minMaxValid) {
            this.$toast.error('Check your min value');
          }
          minChargeValue = this.$refs.minMaxChargeInput.minValue;
        }

        let additionalValid = this.$refs.additionalChargeInput.checkAdditionalValueValidation();
        if (!additionalValid) {
          this.$toast.error('Check your additional charge value');
        }

        let palletMaximumWeight;
        if (this.$refs.palletMaximumWeight) {
          let palletMaximumValid = this.$refs.palletMaximumWeight.checkPalletMaximumValidation();
          if (!palletMaximumValid) {
            this.$toast.error('Check your pallet maximum weight value');
          }
          palletMaximumWeight = this.$refs.palletMaximumWeight.palletMaximumValue;
        }

        let pricingMethod = {
          type: this.selectedLanePricingMethods.key,
          value: pricingMethodValue,
          min: minChargeValue,
          additionalCharge: {
            label: this.$refs.additionalChargeInput.additionalValueLabel,
            value: this.$refs.additionalChargeInput.additionalValue,
          },
          palletMaximumWeight,
        };

        let crossBorder = this.isSelectedCrossBorder();

        let bodyPayload = {
          origin: this.origin,
          destination: this.destination,
          subCity: subCity,
          isBetween: this.between === "between",
          name: this.name,
          description: this.description,
          pricingMethod: pricingMethod,
          metadata: {
            transitDays: this.transitDays > 0 ? this.transitDays : undefined,
            currency: this.currency,
            crossBorder: crossBorder,
          },
        };

        // If the lane involves a cross-border shipment, update the payload
        if (crossBorder) {
          bodyPayload = this.crossBorderPayload(bodyPayload);
        }
        
        let response = await masterAPI(
            API.API_ENDPOINT.lane,
            API.API_METHOD.post,
            undefined,
            JSON.stringify(bodyPayload),
        );

        if (response.status == 200) {
          if (this.isCreateLane) {
            this.alreadySavedLane = true;
            this.id = response.data.id;
            this.$toast.success('Lane created successfully');
          } else {
            this.$toast.success('Lane duplicate successfully');
          }

          if (isSaveExit) {
            this.$router.push({
              name: 'viewallsection',
              params: {preselected: sectionList.lane},
            });
          }
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ''));
        }
      } else {
        this.displayError();
      }
    },
    /**
     * Construct additional payload details for cross-border lanes.
     * @param {Object} bodyPayload - The initial payload to be updated.
     * @returns {Object} Updated payload with cross-border specific data.
    */
    crossBorderPayload(bodyPayload) {
      let crossBorderPricingMethodValue;
      
      if (this.selectedDestinationLanePricingMethods.key === "fixed") {
        crossBorderPricingMethodValue = this.dollarDestinationValue;
      } else {
        crossBorderPricingMethodValue = this.unitPriceDestinationList;
      }

      let crossBorderPricingMethod = {
        type: this.selectedDestinationLanePricingMethods.key,
        value: crossBorderPricingMethodValue
      };

      let crossBorder = this.isSelectedCrossBorder();

      bodyPayload.metadata = {
        transitDays: this.transitDays > 0 ? this.transitDays : undefined,
        crossBorder: crossBorder,
        borderCrossingPoint: this.borderCrossingPoint,
        crossBorderPricingMethod: crossBorderPricingMethod,        
        crossBorderCurrency: {
          originCurrency: this.originCurrency,
          destinationCurrency: this.destinationCurrency,
        },
      }

      return bodyPayload;
    },
    /**
     * Retrieves all lanes and initializes the component's data.
     */
    async getAllLanes() {
      this.lanesList = [];

      let response = await masterAPI(
          API.API_ENDPOINT.lanes,
          API.API_METHOD.get,
          undefined,
          undefined,
      );

      if (response.status == 200) {
        this.lanesList = response.data;
        localStorage.setItem('laneList', JSON.stringify(response.data));

        if (this.laneId) {
          let lane = this.lanesList.map(item => {
            if (item.id == this.laneId) {
              return item;
            }
          }).filter(function(element) {
            return element !== undefined;
          });

          if (lane.length > 0) {
            let laneDetail = lane[0];
            this.borderSelected = laneDetail.metadata?.crossBorder ? 'crossBorder' : 'notCrossBorder';

            this.$nextTick(() => {
              this.origin = laneDetail.origin;
              this.destination = laneDetail.destination;
              let subCityOrigin = laneDetail.subCity.origin;
              let subCityDestination = laneDetail.subCity.destination;
              this.additionalSubCityList = subCityOrigin;
              this.additionalCityToGroupList = subCityDestination;
              this.isAddSubCity = subCityOrigin.length == 0 ? false : true;
              this.isAddCityToGroup =
                  subCityDestination.length == 0 ? false : true;
              
              this.between = laneDetail.isBetween ? 'between' : 'notBetween';
              this.name = laneDetail.name;
              this.transitDays = laneDetail.metadata?.transitDays;
              this.description = laneDetail.description;

              let pricingType = laneDetail.pricingMethod.type;
              let priceMethodName = getMethodKeyByName(
                  this.priceMethodList,
                  pricingType,
              );

              let priceMethodDetail = getOptionDetailByName(
                  this.priceMethodList,
                  priceMethodName,
              );

              this.selectedLanePricingMethods = priceMethodDetail;
              this.selectedPriceMethod = priceMethodName;

              setTimeout(() => {
                document.querySelector(
                    '#editLanePriceMethod .choices__item',
                ).innerText = this.selectedPriceMethod;
              }, 30);

              localStorage.selectedPriceMethod = priceMethodName;

              if (priceMethodName == pricingMethodType.fixed) {
                this.dollarValue = laneDetail.pricingMethod?.value;
              } else {
                this.unitPriceList = laneDetail.pricingMethod?.value;
              }              
              
              // Set cross border values
              if (laneDetail.metadata?.crossBorder) {  
                let metadata = laneDetail.metadata;
                this.borderCrossingPoint = metadata.borderCrossingPoint;
                let crossBorderPricingMethod = metadata.crossBorderPricingMethod;
                
                let pricingTypeDestination = crossBorderPricingMethod.type;
                let priceMethodNameDestination = getMethodKeyByName(
                    this.priceMethodList,
                    pricingTypeDestination,
                );

                let priceMethodDestination = getOptionDetailByName(
                    this.priceMethodList,
                    priceMethodNameDestination,
                );

                this.selectedDestinationLanePricingMethods = priceMethodDestination;
                this.selectedDestinationPriceMethod = priceMethodNameDestination;

                setTimeout(() => {
                  document.querySelector(
                      '#editLaneDestinationPriceMethod .choices__item',
                  ).innerText = this.selectedDestinationPriceMethod;
                }, 30);
                
                if (priceMethodNameDestination == pricingMethodType.fixed) {
                  this.dollarDestinationValue = crossBorderPricingMethod.value;
                } else {
                  this.unitPriceDestinationList = crossBorderPricingMethod.value;
                }

                this.originCurrency = metadata.crossBorderCurrency.originCurrency;
                this.destinationCurrency = metadata.crossBorderCurrency.destinationCurrency;
                
              } else {
                this.currency = laneDetail.metadata?.currency;
              }

              if (laneDetail.pricingMethod.min) {
                this.$refs.minMaxChargeInput.minValue =
                    laneDetail.pricingMethod.min;
                this.$refs.minMaxChargeInput.showMinInput = true;
              }

              if (laneDetail.pricingMethod.additionalCharge?.value) {
                this.$refs.additionalChargeInput.additionalValue =
                    laneDetail.pricingMethod.additionalCharge?.value;
                this.$refs.additionalChargeInput.additionalValueLabel =
                    laneDetail.pricingMethod.additionalCharge.label;
                this.$refs.additionalChargeInput.showAdditionalInput = true;
              }

              if (laneDetail.pricingMethod.palletMaximumValue) {
                this.$refs.palletMaximumWeight.palletMaximumValue =
                    laneDetail.pricingMethod.palletMaximumWeight;
                this.$refs.palletMaximumWeight.showPalletMaximumInput = true;
              }

              let dbObject = {
                origin: laneDetail.origin,
                destination: laneDetail.destination,
                additionalSubCityList: laneDetail.subCity.origin,
                additionalCityToGroupList: laneDetail.subCity.destination,
                between: laneDetail.isBetween,
                name: laneDetail.name,
                description: laneDetail.description,
                selectedPriceMethod: laneDetail.pricingMethod.type,
                dollarValue: laneDetail.pricingMethod?.value,
                unitPriceList: laneDetail.pricingMethod?.value,
              };
              this.dbLane = dbObject;
            });
          }
        }
      }
    },
    async getLanePricingMethods() {
      this.priceMethodList = await accountModuleApi(
          API.API_ENDPOINT.lanePricingMethods,
      );
      this.getAllLanes();
    },

    /**
     * Handle the selection of a base unit for the origin pricing
     * @param {String} value - The selected base unit
     */
    selectBaseUnits(value) {
      this.selectedBaseUnit = value;
      this.isViewClassBreaks = false;
      this.selectedClassUnits = '';
    },
    /**
     * Handle the selection of a base unit for the destination pricing
     * @param {String} value - The selected base unit
     */
    selectBaseDestinationUnits(value) {
      this.selectedDestinationBaseUnit = value;
      this.isDestinationViewClassBreaks = false;
      this.selectedDestinationClassUnits = '';
    },
    /**
     * Handle the selection of class units for the origin pricing
     * @param {String} newVal - The selected class units
     */
    selectClassUnits(newVal) {
      this.isViewClassBreaks = false;
      this.classRateInvalid = false;
      this.selectedClassUnits = newVal;
    },
    /**
     * Handle the selection of class units for the destination pricing
     */
    selectDestinationClassUnits(newVal) {
      this.isDestinationViewClassBreaks = false;
      this.classRateDestinationInvalid = false;
      this.selectedDestinationClassUnits = newVal;
    },
    /**
     * Fetches origin and destination data via an API,
     * processes the data, and updates the `originDestinationList` property.
     */
    async getOriginDestinationViaApi() {
      let response = await masterAPI(
          API.API_ENDPOINT.eiaBaseRates,
          API.API_METHOD.get,
          undefined,
          undefined,
      );

      if (response.status == 200) {
        let originDestination = response.data;

        this.originDestinationList = originDestination.map(option => ({
          id: option.key,
          text: option.name,
        }));
      }
    },
    /**
     * Get the BorderCrossingPoint data from an address autocomplete component
     * @param {string} userInput - The selected address
     */
    getBorderCrossingPoint(userInput) {
      this.borderCrossingPoint = userInput;
      this.borderCrossingPointInvalid = false;
    },
  },
  created() {
    this.getLanePricingMethods();
  },
};
</script>

<style>
.border-radios p {
  font-size: 13px;
  font-weight: 600;
  text-align: left;
}
</style>